<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <hr style="margin:0"/>
              <v-skeleton-loader
                  :loading="loading"
                  v-if="isLoaded"
                  type="table"
              >
              </v-skeleton-loader>
              <v-card-title>
                Inquiries ({{ totalRecords }})
                <v-spacer></v-spacer>
                <v-text-field
                    @keydown.enter="searchQueryData"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="pagination"
                  :server-items-length="totalRecords"
                  :footer-props="{
                  itemsPerPageOptions: rowsPerPageItems,
                  showFirstLastPage: true
                }"
                  :loading="loading"
                  v-show="!isLoaded"
                  mobile-breakpoint="800"
                  class="elevation-0"
              >
                <template v-slot:item.created_at="{ item }">
                  <div class="text-truncate">
                    {{ moment(item.created_at).fromNow() }}
                  </div>
                </template>
              </v-data-table>
              <!-- this dialog is used for both create and update -->
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
  INQUIRIES_LIST,
  CLEAR_INQUIRIES_ERRORS
} from "@/core/services/store/inquiries.module";

export default {
  name: "inquiries",
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "name",
          name: "name",
          filterable: true,
          sortable: false
        },
        {
          text: "Email",
          value: "email",
          name: "email",
          filterable: true,
          sortable: false
        },
        {
          text: "Phone",
          value: "phone",
          name: "phone",
          filterable: false,
          sortable: false
        },
        {
          text: "Comment",
          value: "comment",
          name: "comment",
          filterable: true,
          sortable: false
        },
        {
          text: "Created",
          value: "created_at",
          name: "created_at",
          filterable: false,
          sortable: true
        }
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        mustSort: true,
        multiSort: false,
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [10, 15, 20, 30, 40, 50],
      columns: [],
      items: [],
      dialog: false,
      rDialog: false,
      editedItem: {}
    };
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_INQUIRIES_ERRORS);
      if (!val) this.editedItem = {};
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          "&columns[" +
          i +
          "][data]=" +
          this.headers[i].value +
          "&columns[" +
          i +
          "][name]=" +
          this.headers[i].name +
          "&columns[" +
          i +
          "][searchable]=" +
          this.headers[i].filterable +
          "&columns[" +
          i +
          "][orderable]=" +
          this.headers[i].sortable +
          "&columns[" +
          i +
          "][search][value]=" +
          "&columns[" +
          i +
          "][search][regex]=" +
          false
      );
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Inquiries Management", route: "inquiries"},
      {title: "Inquiries"}
    ]);
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise(resolve => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fecth per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params)
            .map(key => {
              return (
                  encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
              );
            })
            .join("&");
        this.getHeaderIndex(params.sortBy[0]);
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 5 : this.headerindex) +
            "&order[0][dir]=" +
            (params.descending ? "desc" : "asc");
        this.$store
            .dispatch(INQUIRIES_LIST, {q: query, columns: this.columns})
            .then(res => {
              this.draw++;
              let items = res.records.data;
              let total = res.records.recordsFiltered;
              this.loading = !this.loading;
              this.isLoaded = !this.isLoaded;
              resolve({
                items,
                total
              });
            });
      });
    },
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    closeDialog() {
      this.dialog = !this.dialog;
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function(ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.inquiries.errors
    })
  }
};
</script>
